import { RouteInfo } from "./vertical-sidebar.metadata";

export const ADMINROUTES: RouteInfo[] = [
    
   
    {
        path: '/apps/updated-jobs',
        title: 'Jobs',
        icon: 'mdi mdi-file-cloud',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        
        submenu: []
    },
    {
        // path: 'https://apps-demo-1.trilloapps.com/cloud/fm/',
        path: '/apps/file-manager',
        title: 'File Manager',
        icon: 'folder',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '#',
        title: 'Trillo Workbench',
        icon: 'send',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '',
        title: 'Separator',
        icon: '',
        class: 'dropdown-divider',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    // {
    //     path: '/apps/upload-files',
    //     title: 'Upload Images',
    //     icon: 'mdi mdi-cloud-upload',
    //     class: '',
    //     label: '',
    //     labelClass: '',
    //     extralink: false,
    //     submenu: []
    // },
    // {
    //     path: '/apps/image-grouping',
    //     title: 'Image Grouping',
    //     icon: 'mdi mdi-group',
    //     class: '',
    //     label: '',
    //     labelClass: '',
    //     extralink: false,
    //     submenu: []
    // },
    // {
    //     path: '/apps/slider-setting',
    //     title: 'Slider Setting',
    //     icon: 'mdi mdi-settings',
    //     class: '',
    //     label: '',
    //     labelClass: '',
    //     extralink: false,
    //     submenu: []
    // },
    // {
    //     path: '/apps/updated-jobs',
    //     title: 'Updated Jobs',
    //     icon: 'mdi mdi-file-cloud',
    //     class: '',
    //     label: '',
    //     labelClass: '',
    //     extralink: false,
    //     submenu: []
    // },
    {
        path: '/apps/logs',
        title: 'Logs',
        icon: 'mdi mdi-blogger',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/apps/tasks',
        title: 'Tasks',
        icon: 'Layout',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/apps/bulk-upload',
        title: 'Bulk Upload',
        icon: 'mdi mdi-cloud-upload',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    // {
    //     path: '/apps/file-browser',
    //     title: 'File Browser',
    //     icon: 'mdi mdi-folder-outline',
    //     class: '',
    //     label: '',
    //     labelClass: '',
    //     extralink: false,
    //     submenu: []
    // },
    {
        path: '/apps/reports',
        title: 'Reports',
        icon: 'mdi mdi-file-cloud',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/apps/data-manager',
        title: 'Data Manager',
        icon: 'mdi mdi-table',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/apps/data-tables',
        title: 'Data Tables',
        icon: 'mdi mdi-table',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/apps/manage-tables',
        title: 'Manage Tables',
        icon: 'mdi mdi-table-edit',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/apps/data-dictionary',
        title: 'Data Dictionary',
        icon: 'mdi mdi-library-books',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/apps/import-csv',
        title: 'Import CSV',
        icon: 'mdi mdi-cloud-upload',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },

    {
        path: '/apps/user-management',
        title: 'Admin',
        icon: 'mdi mdi-account-box',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/apps/matching',
        title: 'Matching',
        icon: 'mdi mdi-file-document',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/apps/ga-reports',
        title: 'Google Analytics',
        icon: 'mdi mdi-file-chart',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboards/dashboard1',
        title: 'Dashboard',
        icon: 'mdi mdi-home',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/apps/interactive',
        title: 'Interactive',
        icon: 'mdi mdi-cursor-pointer',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/apps/about',
        title: 'About',
        icon: 'info',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    }, 
];
