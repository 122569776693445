import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class cSessionService {

  constructor() { }

  //lsConfigurations
  cSessionService_SetConfigurations(oData)
  {
    localStorage.setItem('lsConfigurations', JSON.stringify(oData))
  }
  cSessionService_GetConfigurations()
  {
    return JSON.parse(localStorage.getItem('lsConfigurations'));
  }
  //lsSelectedJobType
  cSessionService_SetSelectedJobType(oData)
  {
    localStorage.setItem('lsSelectedJobType', JSON.stringify(oData))
  }
  cSessionService_GetSelectedJobType()
  {
    return JSON.parse(localStorage.getItem('lsSelectedJobType'));
  }
  //lsDefaultURL
  cSessionService_SetDefaultURL(oData)
  {
    localStorage.setItem('lsDefaultURL', JSON.stringify(oData))
  }
  cSessionService_GetDefaultURL()
  {
    return JSON.parse(localStorage.getItem('lsDefaultURL'));
  }
  //lsSessionExpiryTime
  cSessionService_SetSessionExpiryTime(oData)
  {
    localStorage.setItem(environment.AccessToken+"SessionExpiryTime", JSON.stringify(oData))
  }
  cSessionService_GetSessionExpiryTime()
  {
    return JSON.parse(localStorage.getItem(environment.AccessToken+"SessionExpiryTime"));
  }
  // Save Selected Table Name
  cSessionService_SetSelectedTableName(oData)
  {
    localStorage.setItem('lsSelectedTable',JSON.stringify(oData))
  }
  cSessionService_GetSelectedTableName()
  {
    return JSON.parse(localStorage.getItem('lsSelectedTable'));
  }
  cSessionService_SetSortingData(oData)
  {
    localStorage.setItem('lSortingOrder',JSON.stringify(oData))
  }
  cSessionService_GetSortingData()
  {
    return JSON.parse(localStorage.getItem('lSortingOrder'));
    
  }
  cSessionService_SetAttributesList(oData)
  {
    localStorage.setItem('lsTableAttributes',JSON.stringify(oData))
  }
  cSessionService_GetAttributesList()
  {
    return JSON.parse(localStorage.getItem('lsTableAttributes'));
  }
  cSessionService_SetDimensionFilter(oData)
  {
    localStorage.setItem('lsDimensionFilter',JSON.stringify(oData))
  }
  cSessionService_GetDimensionFilter()
  {
    return JSON.parse(localStorage.getItem('lsDimensionFilter'));
  }
  cSessionService_SetMetricFilter(oData)
  {
    localStorage.setItem('lsMetricFilter',JSON.stringify(oData))
  }
  cSessionService_GetMetricFilter()
  {
    return JSON.parse(localStorage.getItem('lsMetricFilter'));
  }
  cSessionService_SetSelectedJobDetails(oData)
  {
    localStorage.setItem('lsSelectedJob',JSON.stringify(oData))
  }
  cSessionService_GetSelectedJobDetails()
  {
    return JSON.parse(localStorage.getItem('lsSelectedJob'));
  }
  cSessionService_SetSelectedInteractiveJobDetails(oData)
  {
    localStorage.setItem('lsSelectedInteractiveJob',JSON.stringify(oData))
  }
  cSessionService_GetSelectedInteractiveJobDetails()
  {
    return JSON.parse(localStorage.getItem('lsSelectedInteractiveJob'));
  }
  SessionService_GetUserDetails()
  {
    return JSON.parse(localStorage.getItem('lsUserDetails'));
  }
  cSessionService_SetSelectedTreeNode(oData)
  {
    localStorage.setItem('oSelectedTreeNode',JSON.stringify(oData))
  }
  cSessionService_GetSelectedTreeNode()
  {
    return JSON.parse(localStorage.getItem('oSelectedTreeNode'));
  }
  cSessionService_SetDataDictionaryTree(oData)
  {
    localStorage.setItem('DataDictionaryTree',JSON.stringify(oData))
  }
  cSessionService_GetDataDictionaryTree()
  {
    return JSON.parse(localStorage.getItem('DataDictionaryTree'));
  }
  cSessionService_SetSearchResultObject(oData)
  {
    localStorage.setItem('SelectedItemFromSearchResults',JSON.stringify(oData))
  }
  cSessionService_GetSearchResultObject()
  {
    return JSON.parse(localStorage.getItem('SelectedItemFromSearchResults'));
  }

}