import { Component, AfterViewInit, EventEmitter, Output } from '@angular/core';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig,
  NgbDropdownConfig
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { cSessionService } from 'src/app/services/session.service';
declare var $: any;

@Component({
  selector: 'app-vertical-navigation',
  templateUrl: './vertical-navigation.component.html',
  styleUrls: ['./vertical-navigation.component.css']
})
export class VerticalNavigationComponent implements AfterViewInit {
  bDisplayDotsDropdown : boolean;
  lMenuConfiguration : any;
  bShowSearchIcon = false;
  searchResult : string = "";
  modelClassName : string = "";
  TableDescription : boolean = false;
  @Output() toggleSidebar = new EventEmitter<void>();

  public config: PerfectScrollbarConfigInterface = {};

  public showSearch = false;


  // This is for Mymessages
  mymessages: Object[] = [
    {
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'busy',
      from: 'Sonu Nigam',
      subject: 'I have sung a song! See you at',
      time: '9:10 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'away',
      from: 'Arijit Sinh',
      subject: 'I am a singer!',
      time: '9:08 AM'
    },
    {
      useravatar: 'assets/images/users/4.jpg',
      status: 'offline',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

  public selectedLanguage: any = {
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us'
  }

  public languages: any[] = [{
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us'
  },
  {
    language: 'Español',
    code: 'es',
    icon: 'es'
  },
  {
    language: 'Français',
    code: 'fr',
    icon: 'fr'
  },
  {
    language: 'German',
    code: 'de',
    icon: 'de'
  }]
  sUsername: string;



  constructor(private modalService: NgbModal, private translate: TranslateService ,config: NgbDropdownConfig, private dataService: DataService, private oRouter : Router,private oSessionService : cSessionService,private route: ActivatedRoute) {
    translate.setDefaultLang('en');
    config.autoClose = true;
  }
  ngOnInit(){
    let lConfiguration = this.oSessionService.cSessionService_GetConfigurations();
    this.lMenuConfiguration = lConfiguration.menus;
    this.modelClassName = "";
    this.dataService.bDisplayDotsDropdown.subscribe(res=>{
      this.bDisplayDotsDropdown = res;
    });
    this.sUsername = localStorage.getItem('username')
    this.dataService.bShowHeaderSearch.subscribe(res => {
    setTimeout(() => {
      this.bShowSearchIcon = res;
      
    }, 1000);
     })
    this.dataService.ModelClassName.subscribe(result => {
      this.modelClassName = result;
    })
    this.dataService.bTableDescriptionTab.subscribe(result => {
      this.TableDescription = result;
    })
  }

  changeLanguage(lang: any) {
    this.translate.use(lang.code)
    this.selectedLanguage = lang;
  }

  ngAfterViewInit() { }
  VerticalNavigationComponent_Logout() 
  {
    localStorage.clear();
    location.assign('/authentication/login');
  }

  HeaderComponent_FilterData()
  {
    if(this.searchResult != "")
    {
      this.oRouter.navigate(['/apps/search-results'], { queryParams : { searchPhrase : this.searchResult } });
    }
  }
}
