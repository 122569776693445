import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  constructor() { }
  setTitle(title: string): void {
    document.title = title;
    const favicon : any = document.getElementById('favicon');
    favicon.href = "./assets/whitelabel/trilloappsui/favicon.ico"
  }
}
