import { RouteInfo } from "./vertical-sidebar.metadata";

export const ROUTES: RouteInfo[] = [

    {
        path: '/apps/updated-jobs',
        title: 'Jobs',
        icon: 'mdi mdi-file-cloud',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/apps/data-manager',
        title: 'Data Manager',
        icon: 'mdi mdi-table',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/apps/data-dictionary',
        title: 'Data Dictionary',
        icon: 'fa-solid fa-book',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/apps/data-tables',
        title: 'Data Tables',
        icon: 'mdi mdi-table',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/apps/import-csv',
        title: 'Import CSV',
        icon: 'mdi mdi-cloud-upload',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    
    // {
    //     path: '/apps/logs',
    //     title: 'Logs',
    //     icon: 'mdi mdi-blogger',
    //     class: '',
    //     label: '',
    //     labelClass: '',
    //     extralink: false,
    //     submenu: []
    // },
    // {
    //     path: '/apps/tasks',
    //     title: 'Tasks',
    //     icon: 'Layout',
    //     class: '',
    //     label: '',
    //     labelClass: '',
    //     extralink: false,
    //     submenu: []
    // },

    {
        path: '/apps/about',
        title: 'About',
        icon: 'info',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    }, 
    {
        path: '/dashboards/dashboard1',
        title: 'Dashboard',
        icon: 'mdi mdi-home',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    }
    // {
    //     path: '/apps/upload-files',
    //     title: 'Upload Images',
    //     icon: 'mdi mdi-cloud-upload',
    //     class: '',
    //     label: '',
    //     labelClass: '',
    //     extralink: false,
    //     submenu: []
    // },
    // {
    //     path: '/apps/image-grouping',
    //     title: 'Image Grouping',
    //     icon: 'mdi mdi-group',
    //     class: '',
    //     label: '',
    //     labelClass: '',
    //     extralink: false,
    //     submenu: []
    // },
    // {
    //     path: '/apps/slider-setting',
    //     title: 'Slider Setting',
    //     icon: 'mdi mdi-settings',
    //     class: '',
    //     label: '',
    //     labelClass: '',
    //     extralink: false,
    //     submenu: []
    // },
    // {
    //     path: '/apps/user-management',
    //     title: 'Admin',
    //     icon: 'mdi mdi-account-box',
    //     class: '',
    //     label: '',
    //     labelClass: '',
    //     extralink: false,
    //     submenu: []
    // },

];
