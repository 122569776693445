import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { AuthGuard } from './services/auth.guard';
import { CloudComponent } from './cloud/cloud.component';

export const Approutes: Routes = [
	{
		path: 'cloud/auth',
		component : CloudComponent
	},
	{
		path: '',
		component: FullComponent,
		children: [
			{ path: '', redirectTo: '/authentication/login', pathMatch: 'full' },
			{
				path: 'login',
				loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
			},
			
			{ path: 'apps', loadChildren: () => import('./apps/apps.module').then(m => m.AppsModule),canActivate: [AuthGuard] },
			{ path: 'dashboards', loadChildren: () => import('./dashboards/dashboard.module').then(m => m.DashboardModule),canActivate: [AuthGuard] },
		]
	},
	{
		path: '',
		component: BlankComponent,
		children: [
			{
				path: 'authentication',
				loadChildren:
					() => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
			}
		]
	},
	
	{
		path: '**',
		redirectTo: '/authentication/404'
	}

];
