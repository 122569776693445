import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({

    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'x-app-name': 'auth',
    'x-org-name': 'cloud',
  })
};
@Injectable({
  providedIn: 'root'
})
export class DataService {
  bDisplayDotsDropdown = new BehaviorSubject<boolean>(false);
  bDisplayTableButtons = new BehaviorSubject<boolean>(false);
  bTableAttributes = new BehaviorSubject<any>("");
  oNextDataDictionaryFolder = new BehaviorSubject<any>("");
  bShowHeaderSearch = new BehaviorSubject <boolean>(false); 
  UpdateDBandSchemaDescription = new BehaviorSubject<string>("")
  ModelClassName = new BehaviorSubject<string>("")
  sSelecetedTableName = new BehaviorSubject<any>("");
  bDisplayConfirmationPopupAfterChanges = new BehaviorSubject<boolean>(false);
  bTableDescriptionTab = new BehaviorSubject<boolean>(false);
  accessToken = localStorage.getItem(environment.AccessToken);
  httpOptionsWithAccessTokenUM: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-app-name': 'main',
      'x-org-name': 'cloud',
      'Authorization': 'Bearer ' + this.accessToken
    })
  }
  httpContentType:any = {
    headers: new HttpHeaders({
      'Accept':'*/*',
      'x-app-name': 'main',
      'x-org-name': 'cloud',
      'Authorization': 'Bearer ' + this.accessToken,
    })
  }
  constructor(private http: HttpClient) { }
  //add new job
  DataService_StartNewJob(body, triggerAPI): Observable<any> 
  {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/' + triggerAPI, body, this.httpOptionsWithAccessTokenUM);
  }
  //get all jobs
  DataService_GetAllJobs(body, triggerAPI): Observable<any> 
  {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/' + triggerAPI, body, this.httpOptionsWithAccessTokenUM);
    // return this.http.post<any>(environment.BaseURL + '/ds/function/shared/getAllJobsByUserIdV2', body, this.httpOptionsWithAccessTokenUM);
  }
  //image upload
  DataService_GetUnSignedURLForUploadedImage(body): Observable<any> 
  {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/upload', body, this.httpContentType);
  }
  //show uploaded images
  DataService_GetAllImagesByJobId(body, triggerAPI): Observable<any> 
  {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/' + triggerAPI, body, this.httpOptionsWithAccessTokenUM);
  }
   //Run current job 
   DataService_RunCurrentJob(triggerAPI,body): Observable<any> 
   {
     return this.http.post<any>(environment.BaseURL + '/ds/function/shared/'+triggerAPI, body, this.httpOptionsWithAccessTokenUM);
   }
  //Get task status
  DataService_GetTaskStatus(body, triggerAPI): Observable<any> 
  {
    return this.http.get<any>(environment.BaseURL + '/ds/function/shared/' + triggerAPI + "?id="+body.jobId, this.httpOptionsWithAccessTokenUM);
  }
  //view File details
  DataService_GetFileDetails(fileResultAPI,body): Observable<any> 
  {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/'+fileResultAPI, body, this.httpOptionsWithAccessTokenUM);
  }
  //view File
  DataService_GetMatchedFileDetails(body): Observable<any> 
  {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/folder/retieveSignedUrl', body, this.httpOptionsWithAccessTokenUM);
  }
  //delete job image
  DataService_DeleteJobImage(body): Observable<any> 
  {
    return this.http.delete<any>(environment.BaseURL + '/ds/delete/shared/common/JobImage?permanent=false&id=' + body, this.httpOptionsWithAccessTokenUM);
  }
  //Damage detection 
  DataService_DamageDetection(body): Observable<any> 
  {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DamageDetectionTrigger', body, this.httpOptionsWithAccessTokenUM);
  }
  //View damaged identified objects
  DataService_GetDamagedIdentifiedObjects(body): Observable<any> 
  {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetDamageDetectionResult', body, this.httpOptionsWithAccessTokenUM);
  }
  // user management api's
   //users list
   DataService_GetUsersList(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/page/auth/vault', body, this.httpOptionsWithAccessTokenUM);
  }
   //upload  users
   DataService_UploadUsers(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/authds/uploadUsers', body , this.httpContentType );

  }
   //create user
   DataService_CreateUser(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/um/newUser', body, this.httpOptionsWithAccessTokenUM);
  }
   //edit user
   DataService_EditUser(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/um/editUser', body, this.httpOptionsWithAccessTokenUM);
  }
    //reset user password
    DataService_ResetUserPassword(body): Observable<any> {
      return this.http.post<any>(environment.BaseURL + '/_service/um/resetPassword', body, this.httpOptionsWithAccessTokenUM);
    }
      //suspend user
   DataService_SuspendUser(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/um/toggleSuspendActive', body, this.httpOptionsWithAccessTokenUM);
  }
   //delete user
   DataService_DeleteUser(id : any): Observable<any> {
    return this.http.delete<any>(environment.BaseURL + '/_service/um/deleteUser' + "?id="+ id , this.httpOptionsWithAccessTokenUM  );
  }
  //roles list
  DataService_GetRolesList(body): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/_service/um/getRoles', httpOptions);
  }
  // new user role
  DataService_GetNewUserRole(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/um/saveRole', body , this.httpOptionsWithAccessTokenUM);
  }
    // edit  role
    DataService_GetEditRole(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/um/saveRole', body , this.httpOptionsWithAccessTokenUM);
  }
    //logs list
  DataService_GetLogsList(body): Observable<any> 
  {
    return this.http.post<any>(environment.BaseURL + '/ds/page/AuditLog', body, this.httpOptionsWithAccessTokenUM);
  }
  //logs details list
  DataService_GetLogsDestailsList(id : any): Observable<any> 
  {

  return this.http.get<any>(environment.BaseURL + '/ds/object/shared/common/AuditLog' + "?id="+id, this.httpOptionsWithAccessTokenUM );
  }
  // Task Details 
  DataService_GetTaskDetails(body): Observable<any> 
  {
    return this.http.post<any>(environment.BaseURL + '/ds/page/SysTaskExec', body, this.httpOptionsWithAccessTokenUM);
  }
  // Tasks Cancel
  DataService_CancelTasks(body): Observable<any> 
  {
    return this.http.post<any>(environment.BaseURL + '/_service/systask/cancelTasks', body, this.httpOptionsWithAccessTokenUM);
  }

  //groups list
  DataService_GetUsersGroup(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GroupList', body, this.httpOptionsWithAccessTokenUM);
  }
  DataService_GetGroupData(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GroupDetail', body,this.httpOptionsWithAccessTokenUM);
  }
  //new group
  DataService_AddNewGroup(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/newGroup', body, this.httpOptionsWithAccessTokenUM);
  }
  //total groups
  DataService_GetTotalGroups(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/function/shared/newGroup', body, httpOptions);
  }
  //add member (groups)
  DataService_AddMember(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/addMember', body, this.httpOptionsWithAccessTokenUM);
  }
    //delete member (groups)
    DataService_DeleteMember(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/deleteMember', body, this.httpOptionsWithAccessTokenUM);
  }
    //update member (groups)
    DataService_UpdateMember(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/updateMember', body, this.httpOptionsWithAccessTokenUM);
  }
  // tenants
  //get total tenants list(sidebar)
  DataService_GetTotalTenants(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/page/auth/vault', body, this.httpOptionsWithAccessTokenUM);
  }
  //get selected tenants data
  DataService_GetTenantsData(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/page/auth/vault', body, this.httpOptionsWithAccessTokenUM);
  }
  //add new tenant
  DataService_AddNewTenant(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/save/auth/vault/Org', body, this.httpOptionsWithAccessTokenUM);
  }
   //build number
   DataService_GetBuildNumber(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_systemInfo', body, httpOptions);
  }

  //Get anchoring result
  DataService_GetAnchoringStatus(body): Observable<any> 
  {
    return this.http.get<any>(environment.BaseURL + '/ds/function/shared/getAnchoringResult'+"?jobId="+body, this.httpOptionsWithAccessTokenUM);
  }
  //Get Settings result
  DataService_GetSettingsStatus(body): Observable<any> 
  {
    return this.http.get<any>(environment.BaseURL + '/ds/function/shared/getSettingsResult'+"?jobId="+body, this.httpOptionsWithAccessTokenUM);
  }
  //Get job Congifurations
  DataService_GetJobConfigurations(): Observable<any> 
  {
    return this.http.get<any>(environment.BaseURL + '/ds/function/shared/getJobConfigs', this.httpOptionsWithAccessTokenUM);
  }
  // Get Reports 
  DataService_GetReportsName() : Observable<any>
  {
    return this.http.get<any>(environment.BaseURL + '/ds/md/listMetadata?modelClassNames=ReportM,ChartM&option=published', this.httpOptionsWithAccessTokenUM );
  }
  // Get Details according reports name 
  DataService_GetReportsTableDetails(body,id) : Observable<any>
  {
    return this.http.post<any>(environment.BaseURL + '/ds/pageByMatadataDataSource/'+id , body , this.httpOptionsWithAccessTokenUM );
  }
  // Get reports attributes 
  DataService_GetReportsAttributesDetails(id) : Observable<any>
  {
    return this.http.get<any>(environment.BaseURL + '/ds/md/getMetadata/'+id  , this.httpOptionsWithAccessTokenUM );
  }
  // Get Details according reports name 
  DataService_GetFilterRecords(body,id) : Observable<any>
  {
    return this.http.post<any>(environment.BaseURL + '/ds/pageByMatadataDataSource/'+id , body , this.httpOptionsWithAccessTokenUM );
  }
  //Get Tables
  DataService_GetTablesName() : Observable<any>
  {
    return this.http.get<any>(environment.BaseURL + '/ds/md/listMetadata?modelClassNames=ClassM&visibility=dataManager', this.httpOptionsWithAccessTokenUM );
  }
  // Get Data Sources 
  DataService_GetDataSourceName() : Observable<any>
  {
    return this.http.get<any>(environment.BaseURL + '/ds/md/getClassesBySchema?visibility=dataManager', this.httpOptionsWithAccessTokenUM );
  }
  // Get Details according table name 
  DataService_GetDataTableAttributes(body) : Observable<any>
  {
    return this.http.get<any>(environment.BaseURL + '/ds/md/getMetadata/ClassM/'+body.name+'?fileName='+body.fileName, this.httpOptionsWithAccessTokenUM );
  }
  // get tree for data source
  DataServiceGetTreeForDataSource(): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/ds/md/metaDataTree?modelClassName=ClassM&dataDictionaryMode=true',this.httpOptionsWithAccessTokenUM)
  }
  DataServiceGetTreeForDataSourceWithSystemCheck(): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/ds/md/metaDataTree?modelClassName=ClassM&option=includeSystem', this.httpOptionsWithAccessTokenUM)
  }
    // update data sorce and schema description
  DataService_UpdateDataSourceAndSchemaDescription(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/md/saveMetadata', body,this.httpOptionsWithAccessTokenUM)
  }
    // get schema and database description
  DataService_GetDatabaseDescription(fileName): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/ds/md/getMetadata/DataSourceM/'+fileName,this.httpOptionsWithAccessTokenUM)
  }
  // get Table description
  DataService_GetTableDescription(fileName): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/ds/md/getMetadata/ClassM/'+fileName,this.httpOptionsWithAccessTokenUM)
  }
      // get schema description
  DataService_GetSchemaDescription(fileName): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/ds/md/getMetadata/SchemaM/'+fileName,this.httpOptionsWithAccessTokenUM)
  }
  // Get table attributes 
  DataService_GetTableRecords(body,dsName: string,name, sSchemaName: string, sAppName: string) : Observable<any>
  {
    if(sSchemaName!==null && sSchemaName!== undefined && sSchemaName!=="")
    {
      if(dsName.includes("common"))
      {
        return this.http.post<any>(environment.BaseURL + '/ds/page/' + sAppName +"/" + dsName + "/"+ name, body, this.httpOptionsWithAccessTokenUM );
      }
      else
      {
        return this.http.post<any>(environment.BaseURL + '/ds/page/' + sAppName +"/"+ dsName + '.' + name, body, this.httpOptionsWithAccessTokenUM );
      }
    }
    else
    {
      return this.http.post<any>(environment.BaseURL + '/ds/page/' + sAppName +"/"+ dsName + "/" + name, body, this.httpOptionsWithAccessTokenUM );
    }
  }
  // Sava data of ngx-datatable
  DataService_SaveEditRecords(sFunctionName,sAppName,dsName: string,name,sSchemaName,body) : Observable<any>
  {
    if(sFunctionName == 'saveAll')
    {
      if(sSchemaName!==null && sSchemaName!== undefined && sSchemaName!=="")
      {
        if(dsName.includes("common"))
        { //MySQL ==> appname/classname
          return this.http.post<any>(environment.BaseURL + '/ds/'+ sFunctionName + "/" + sAppName +"/" + dsName + "/"+ name, body, this.httpOptionsWithAccessTokenUM );
        }
        else
        { //postgress / MicroSoft SQL ==> appname/schemaname.classname
          return this.http.post<any>(environment.BaseURL + '/ds/'+ sFunctionName + "/" + sAppName +"/"+ dsName + "/" + sSchemaName + '.' + name, body, this.httpOptionsWithAccessTokenUM );
        }
      }
      else
      {
        return this.http.post<any>(environment.BaseURL + '/ds/'+ sFunctionName + "/" + sAppName +"/"+ dsName + "/" + name, body, this.httpOptionsWithAccessTokenUM );
      }
    }
    else
    {
      return this.http.post<any>(environment.BaseURL + '/ds/function/' + sAppName +"/"+ sFunctionName, body, this.httpOptionsWithAccessTokenUM );
    }
  }
  DataService_SaveColumnAttribute(body) : Observable<any>
  {
    return this.http.post<any>(environment.BaseURL + '/ds/md/saveMetadata', body, this.httpOptionsWithAccessTokenUM );
  }

  DataService_ValidateFileSizeForDicom(nIncommingFileSizeInBytes : number, sIncommingFileName : string)
  {
    if(nIncommingFileSizeInBytes <= 1073741824)
    {
      console.log(`
      File size less than 1 Gb ==>
      File name : ${sIncommingFileName}
      File size : ${nIncommingFileSizeInBytes}
      `);
      return true;
    }
    else
    {
      console.log(`
      File size greater than 1 Gb ==>
      File name : ${sIncommingFileName}
      File size : ${nIncommingFileSizeInBytes}
      `);
      return false;
    }
  }
  // Get CSV Files List
  DataService_GetCsvFilesList(body,GetOCR_CSVFileList) : Observable<any>
  {
    return this.http.post<any>(environment.BaseURL +  '/ds/function/shared/' + GetOCR_CSVFileList , body , this.httpOptionsWithAccessTokenUM );
  }

  DataService_GetCsvFilesURL(body) : Observable<any>
  {
    return this.http.post<any>(environment.BaseURL +  '/ds/function/shared/GetOCR_CSVUrl' , body , this.httpOptionsWithAccessTokenUM );
  }
  DataService_GetPageLayout(body) : Observable<any>
  {
    return this.http.post<any>(environment.BaseURL +  '/ds/function/shared/GetOCR_Result' , body , this.httpOptionsWithAccessTokenUM );
  }
  DataService_DeleteRecord(sAppName,dsName: string,name,sSchemaName,body) : Observable<any>
  {
    if(sSchemaName!==null && sSchemaName!== undefined && sSchemaName!=="")
    {
      if(dsName.includes("common"))
      { //MySQL ==> appname/classname
        return this.http.delete<any>(environment.BaseURL + '/ds/delete/' + sAppName +"/" + dsName + "/"+ name + "?permanent=false&id=" + body, this.httpOptionsWithAccessTokenUM );
      }
      else
      { //postgress / MicroSoft SQL ==> appname/schemaname.classname
        return this.http.delete<any>(environment.BaseURL + '/ds/delete/' + sAppName +"/"+ dsName + "/" + sSchemaName + '.' + name+ "?permanent=false&id=" + body, this.httpOptionsWithAccessTokenUM );
      }
    }
    else
    {
      return this.http.delete<any>(environment.BaseURL + '/ds/delete/' + sAppName +"/"+ dsName + "/" + name+ "?permanent=false&id=" + body, this.httpOptionsWithAccessTokenUM );
    }
  }
  // Get Distinct Values for auto suggestions 
  DataService_GetDistinctValuesForAutoSuggestion(dsName: string,name, sSchemaName: string, sAppName: string) : Observable<any>
  {
    // return this.http.get<any>(environment.BaseURL + '/ds/distinctValues/'+ sAppName +"/" + dsName + "."+ name, this.httpOptionsWithAccessTokenUM);
    if(sSchemaName!==null && sSchemaName!== undefined && sSchemaName!=="")
    {
      if(dsName.includes("common"))
      {
        return this.http.get<any>(environment.BaseURL + '/ds/distinctValues/' + sAppName +"/" + dsName + "/"+ name, this.httpOptionsWithAccessTokenUM );
      }
      else
      {
        return this.http.get<any>(environment.BaseURL + '/ds/distinctValues/' + sAppName +"/"+ dsName + '.' + name, this.httpOptionsWithAccessTokenUM );
      }
    }
    else
    {
      return this.http.get<any>(environment.BaseURL + '/ds/distinctValues/' + sAppName +"/"+ dsName + "/" + name, this.httpOptionsWithAccessTokenUM );
    }
  }


  DataService_FilePreProcessor(body) : Observable<any>
  {
    return this.http.post<any>(environment.BaseURL +  '/ds/function/shared/FilePreProcessor' , body, this.httpOptionsWithAccessTokenUM);
  }

  DataService_ProcessFileDataTrigger(body) : Observable<any>
  {
    return this.http.post<any>(environment.BaseURL +  '/ds/function/shared/ProcessFileDataTrigger' , body, this.httpOptionsWithAccessTokenUM);
  }

  DataService_GetGAPrams(body) : Observable<any>
  {
    return this.http.post<any>(environment.BaseURL +  '/ds/function/shared/GAParams' , body, this.httpOptionsWithAccessTokenUM)
  }

  DataService_GenerateGaReports(body) : Observable<any>
  {
    return this.http.post<any>(environment.BaseURL +  '/ds/function/shared/RunAnalyticReportsTrigger' , body, this.httpOptionsWithAccessTokenUM)
  }

  DataService_GetGAReports(body) : Observable<any>
  {
    return this.http.post<any>(environment.BaseURL +  '/ds/function/shared/AnalyticReportsList' , body, this.httpOptionsWithAccessTokenUM)
  }

  DataService_DeleteSelectedReport(body): Observable<any>
  {
    return this.http.post<any>(environment.BaseURL +  '/ds/function/shared/DeleteAnalyticsReports',body, this.httpOptionsWithAccessTokenUM)
  }

  DataService_GetAnalyticsReportSignedURL(body): Observable<any>
  {
    return this.http.post<any>(environment.BaseURL +  '/ds/function/shared/GetAnalyticReportSignedURL',body, this.httpOptionsWithAccessTokenUM)
  }


  //********************************  File Manager Services  ********************************//

  DataService_GetSearchedFiles(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/search', body,this.httpOptionsWithAccessTokenUM);
  }


  DataService_GetSearchAutoComplete(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/autoComplete', body,this.httpOptionsWithAccessTokenUM);
  }

  // get folders
  cDataService_GetFolders(): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/foldersvc/folders',this.httpOptionsWithAccessTokenUM);
  }

  //get folder and files
  cDataService_GetFoldersAndFiles(body: any): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/foldersvc/folderAndFiles?folderId=' + body.id + '&deleted=' + body.deleted + '&orderBy=' + body.orderBy,this.httpOptionsWithAccessTokenUM);
  }


  cDataService_DownloadSelectedFile(body: any): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/folder/retieveSignedUrl', body,this.httpOptionsWithAccessTokenUM);
  }

  DataService_SyncwithGCPBucket(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/folder/sync', body,this.httpOptionsWithAccessTokenUM);
  }

   //delete folder
   cDataService_DeleteFolder(body: any): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/folder/delete', body,this.httpOptionsWithAccessTokenUM);
  }
  //delete files
  cDataService_DeleteFiles(body: any): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/file/deleteMany', body,this.httpOptionsWithAccessTokenUM);
  } 

   //preprocess
   cDataService_PreProcessDataAsNewTable(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/FilePreProcessor', body,this.httpOptionsWithAccessTokenUM);
  }

  //process data as new table
  cDataService_ProcessDataAsNewTable(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ProcessFileDataTrigger', body,this.httpOptionsWithAccessTokenUM);
  }

  //create folder
  cDataService_CreateFolder(body: any): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/folder/create', body,this.httpOptionsWithAccessTokenUM);
  }

  cDataService_CreateDownloadSignedUrl(body: any): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/folder/createDownloadSignedUrl', body,this.httpOptionsWithAccessTokenUM);
  }

  cDataService_GetTablesList(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetTableNameList',body,this.httpOptionsWithAccessTokenUM);
  }
  cDataService_DeleteJob(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DeleteJobById',body,this.httpOptionsWithAccessTokenUM);
  }
  cDataService_DeleteImage(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DeleteImgById',body,this.httpOptionsWithAccessTokenUM);
  }
  cDataService_GetSignedURL(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetSignedURLById',body,this.httpOptionsWithAccessTokenUM);
  }
  cDataService_UploadInteractiveresultURL(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/InteractiveObjectDetection',body,this.httpOptionsWithAccessTokenUM);
  }

 //Upload Image
 DataService_UploadImage(body): Observable<any>
 {
   return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/upload', body , this.httpContentType);
 }

 DataService_GoogleSTT(body): Observable<any>
 {
   return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GoogleSTT', body , this.httpOptionsWithAccessTokenUM);
 }
 //delete data table 
 DataService_DeleteDataTable(nId):Observable<any>
 {
   return this.http.delete<any>(environment.BaseURL +'/ds/md/deleteMetadata/'+nId, this.httpOptionsWithAccessTokenUM)
 }
 DataService_DownloadCSVUserList (body): Observable<any> 
 {
  return this.http.post<any>(environment.BaseURL +'/ds/function/shared/GetUsersCSV' , body , this.httpOptionsWithAccessTokenUM )
 }
  DataService_DownloadCSVRolesList (body): Observable<any> 
  {
    return this.http.post<any>(environment.BaseURL +'/ds/function/shared/GetRolesCSV' , body , this.httpOptionsWithAccessTokenUM )
  }
  //get Search Results For Data Dictionary
  cDataService_GetSearchResultForDataDictionary(searchPhrase): Observable<any> {
    return this.http.get<any>(environment.BaseURL + '/ds/md/search?searchPhrase='+searchPhrase,this.httpOptionsWithAccessTokenUM);
  }
  DataService_GetDistinctValuesForAutoSuggestionFromAnotherTable(body): Observable<any> 
  {
   return this.http.post<any>(environment.BaseURL +'/ds/function/shared/NMBRCAutoSuggestions' , body , this.httpOptionsWithAccessTokenUM )
  }
}

