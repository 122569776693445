export const environment = 
{
  production: window["env"]["production"] || false,
  BaseURL: window["env"]["BaseURL"] || 'https://apps-demo-1.trilloapps.com',
  AccessToken : window["env"]["AccessToken"] || 'lsTrilloAppsAccessToken',
  Title: window["env"]["Title"] || 'Trillo Apps',
  ImagesURL : window["env"]["ImagesURL"] || '/assets/whitelabel/trilloappsui/',
  HeaderButtons: window["env"]["HeaderButtons"] || false,
};

// Trillo Apps
// docker run -p 8080:8080 -e production=false -e BaseURL='https://apps-demo-1.trilloapps.com' -e Title='Trillo Apps' -e AccessToken=lsTrilloAppsAccessToken -e ImagesURL='/assets/whitelabel/trilloappsui/' -e HeaderButtons=false  imagename

// Talentnet
// docker run -p 8080:8080 -e production=false -e BaseURL='https://app.talentnet.trilloapps.com' -e Title='Talentnet' -e AccessToken=lsTalentnetAccessToken -e ImagesURL='/assets/whitelabel/talentnet/' -e HeaderButtons=false  imagename

// Talentnet 2
// docker run -p 8080:8080 -e production=false -e BaseURL='https://app.talentnet2.trilloapps.com' -e Title='Talentnet' -e AccessToken=lsTalentnetAccessToken -e ImagesURL='/assets/whitelabel/talentnet2/' -e HeaderButtons=false  imagename

// Media Monks (Staging / Dev)
// docker run -p 8080:8080 -e production=false -e BaseURL='https://dev-mediamonks-data-warehouse.trilloapps.com' -e Title='Media Monks' -e AccessToken=lsMediaMonksAccessToken -e ImagesURL='/assets/whitelabel/mediamonks/' -e HeaderButtons=true  imagename

// Media Monks Prod
// docker run -p 8080:8080 -e production=true -e BaseURL='https://mediamonks-data-warehouse.trilloapps.com' -e Title='Media Monks' -e AccessToken=lsMediaMonksAccessToken -e ImagesURL='/assets/whitelabel/mediamonks/' -e HeaderButtons=true  imagename

// SEO Auditor
// docker run -p 8080:8080 -e production=false -e BaseURL='https://app.seo.trilloapps.com' -e Title='SEO Auditor' -e AccessToken=lsSEOAccessToken -e ImagesURL='/assets/whitelabel/seo/' -e HeaderButtons=false  imagename

// FepUs
// docker run -p 8080:8080 -e production=false -e BaseURL='https://app.fep-us.trilloapps.com' -e Title='FepUs' -e AccessToken=lsFepUsAccessToken -e ImagesURL='/assets/whitelabel/fepus/' -e HeaderButtons=false  imagename

// DigsFact
// docker run -p 8080:8080 -e production=false -e BaseURL='https://digsfact-ai.trilloapps.com' -e Title='DigsFact' -e AccessToken=lsDigsFactAccessToken -e ImagesURL='/assets/whitelabel/digsfact/' -e HeaderButtons=false  imagename