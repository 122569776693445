import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';
import { cSessionService } from '../services/session.service';
import { VerticalSidebarService } from '../shared/vertical-sidebar/vertical-sidebar.service';

@Component({
  selector: 'app-cloud',
  templateUrl: './cloud.component.html',
  styleUrls: ['./cloud.component.css']
})
export class CloudComponent implements OnInit {
  a_t_nonce : string;
  bDisplayAjaxLoginFields: boolean = true;
  bDisplayErrorMessage : boolean = false;
  sUserRole : string;
  msg: string = "Authetication Failed.";
  oProjectConfigurations : any = {title : '',logo : '', background : ''};
  bLoading : boolean = false;
  sResponseMessage : string = "";
  constructor(private oActivatedRoute : ActivatedRoute, private oAuthService : AuthService,private routes: Router, private oSessionService : cSessionService, private oMenuServise: VerticalSidebarService) {
    this.oActivatedRoute.queryParams.subscribe(params => {
      this.a_t_nonce = params?.a_t_nonce;
      console.log("Query Params ==>>>>",this.a_t_nonce);
    })
   }
  ngOnInit(): void 
  {
    if(this.a_t_nonce)
    {
      this.bLoading = true;
      this.sResponseMessage = "";
      this.oAuthService.AuthService_GetATNonce(this.a_t_nonce).subscribe(result => {
        console.log("Get Token ==.",result);
        if(result.status == 'failed')
        {
          this.bLoading = false;
          this.sResponseMessage = result.message;
        }
        else
        {
          this.bLoading = false;
          console.log("LoginComponent_AJAXLogin : ==> ", result)
          localStorage.setItem(environment.AccessToken, result.accessToken);
          localStorage.setItem("username", result.user.userId);
          localStorage.setItem("userRole", result.user.role);
          localStorage.setItem("userId", result.user.id);
          this.LoginComponent_GetConfigurations();
        }
      })
    }
  }
  LoginComponent_GetConfigurations()
  {
    try 
    {
      this.oAuthService.AuthService_GetConfigurations().subscribe(result => 
    {
      // console.info("DataService_GetConfigurations : Data ==> ", result.data[0].config);
      
      this.oSessionService.cSessionService_SetConfigurations(result.data);
      console.log("navigatioon called")
      this.routes.navigateByUrl(this.LoginComponent_ReturnRedirectionURL());
    },
    (error) => 
    {
      this.msg = error.error.message;
      this.bDisplayErrorMessage = true;
      setTimeout(() => {this.bDisplayErrorMessage = false; }, 5000);
      console.error("DataService_GetConfigurations: Error ==> ", error);
    });
    }
    catch (catchError) 
    {
      console.error("DataService_GetConfigurations: ERROR ==> ", catchError);
    }  
  }
  LoginComponent_ReturnRedirectionURL()
  {
    let oFirstMenuItem = this.oSessionService.cSessionService_GetConfigurations().menus[0];
    var lMenuItems = [];
    let sURL : string = '/authentication/404';
    this.sUserRole = localStorage.getItem("userRole");
    this.oMenuServise.adminItems.subscribe(async menuItems =>{lMenuItems = menuItems;});
    // if (this.sUserRole === 'admin')
    // {
    // this.oMenuServise.adminItems.subscribe(async menuItems =>{lMenuItems = menuItems;});
    // }
    // else
    // {
    //   this.oMenuServise.items.subscribe(async menuItems =>{lMenuItems = menuItems;});
    // }

    for (let nFirstMenuItemIndex = 0; nFirstMenuItemIndex < lMenuItems.length; nFirstMenuItemIndex++) 
    {
      const oMenuItem = lMenuItems[nFirstMenuItemIndex];
      if(oMenuItem.title === oFirstMenuItem.title)
      {
        sURL = lMenuItems[nFirstMenuItemIndex].path;
        break;
      }
    }

    this.oSessionService.cSessionService_SetDefaultURL(sURL);
    return sURL;
  }
}
