import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouteInfo } from './vertical-sidebar.metadata';
import { VerticalSidebarService } from './vertical-sidebar.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { cSessionService } from 'src/app/services/session.service';
import {Location} from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-vertical-sidebar',
  templateUrl: './vertical-sidebar.component.html',
  styleUrls: ['./vertical-sidebar.component.scss']
})
export class VerticalSidebarComponent {
  showMenu = '';
  showSubMenu = '';
  public sidebarnavItems: RouteInfo[] = [];
  path = '';

  @Input() showClass: boolean = false;
  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();
  sUserRole: string;
  lConfigurations: any = [];
  handleNotify() {
    this.notify.emit(!this.showClass);
  }
  constructor(private menuServise: VerticalSidebarService, private router: Router, private oSessionService : cSessionService,private _location: Location) {}
  ngOnInit()
  {
    this.sUserRole= localStorage.getItem('userRole');
    let lConfig = this.oSessionService.cSessionService_GetConfigurations();
    this.lConfigurations = lConfig.menus;
    this.VerticalSidebarComponent_DisplayMenuItemBasedOnUserRole();
  }
  VerticalSidebarComponent_DisplayMenuItemBasedOnUserRole()
{
  var lMenuItems = [];
  this.sidebarnavItems=[];
  // this.sidebarnavItems = menuItems;

  if (this.sUserRole === 'admin')
  {
    this.menuServise.adminItems.subscribe(async menuItems =>{lMenuItems = menuItems;});
  }
  else
  {
    this.menuServise.items.subscribe(async menuItems =>{lMenuItems = menuItems;});
  }
  
  // Active menu
  this.sidebarnavItems.filter(m => m.submenu.filter(
  (s) =>
  {
    if (s.path === this.router.url)
    {
      this.path = m.title;
    }
  }
  ));
  this.VerticalSidebarComponent_AddExpandClass(this.path);
  for (let nConfigurationItemIndex = 0;nConfigurationItemIndex < this.lConfigurations.length;nConfigurationItemIndex++) 
  {
    let element = this.lConfigurations[nConfigurationItemIndex];
    for(let nMenuItemToPushIndex = 0; nMenuItemToPushIndex < lMenuItems.length; nMenuItemToPushIndex++)
    {
      let item = lMenuItems[nMenuItemToPushIndex];
      if(element.title === item.title)
      {
        this.sidebarnavItems.push(item);
        break;
      }
    }
  }

  
  console.log("User Role : ==>  '%s' && Menu Items :  ==> ",this.sUserRole, this.sidebarnavItems);
}
VerticalSidebarComponent_AddExpandClass(element: any)
{
  if (element === this.showMenu)
  {
    this.showMenu = '0';
  } else
  {
    this.showMenu = element;
  }
}

  addExpandClass(element: any) {
    console.log("ELEMENT ==>>>",element)
    console.log("Title::",element);
    // if(element == 'File Manager')
    // {
    //   let a= document.createElement('a');
    //   a.target= '_blank';
    //   a.href= 'https://apps-demo-1.trilloapps.com/cloud/fm/';
    //   a.click();
    //   this._location.back();
    // }
    if(element == 'Trillo Workbench')
    {
      let a= document.createElement('a');
      a.target= '_blank';
      a.href= 'https://apps-demo-1.trilloapps.com/cloud/main/';
      a.click();
      this._location.back();
    }
    else
    {
      if (element === this.showMenu) {
        this.showMenu = '0';
      } else {
        this.showMenu = element;
      }
    }
    
  }

  VerticalSidebarComponent_AddActiveClass(element: any)
  {
    if (element === this.showSubMenu)
    {
      this.showSubMenu = '0';
    } else
    {
      this.showSubMenu = element;
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}
