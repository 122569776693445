<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left mr-auto">
    <li class="nav-item d-none d-lg-block">
        <a *ngIf="lMenuConfiguration.length > 1" (click)="toggleSidebar.emit()" class="text-white nav-link sidebartoggler waves-effect waves-light" style="color: #fff !important; text-decoration: none !important;"
            href="javascript:void(0)">
            <i class="ti-menu"></i>
        </a>
    </li>
</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-right">

  



    <!-- ============================================================== -->
    <!-- Search -->
    <!-- ============================================================== -->
    <!-- <li class="nav-item search-box">
        <a (click)="showSearch = !showSearch" class="nav-link waves-effect waves-dark" href="javascript:void(0)" style="color: #fff !important; text-decoration: none !important;">
            <i class="ti-search"></i>
        </a>
        <form class="app-search position-absolute" [ngClass]="{'show-search': showSearch}">
            <input type="text" class="form-control" placeholder="Search &amp; enter">
            <a class="srh-btn" (click)="showSearch = !showSearch">
                <i class="ti-close"></i>
            </a>
        </form>
    </li> -->
    <!-- ============================================================== -->
   
    <!-- User profile and search -->
    <!-- ============================================================== -->
   <li *ngIf="bShowSearchIcon" [ngClass]="((modelClassName != '' && modelClassName == 'ClassM') && TableDescription == false) ? 'ClassM' : ''">
    <div class="input-wrapper">
        <button class="Header-Search-icon"> 
            <i class="mdi mdi-magnify mt-1"></i>
        </button>
        <input placeholder="search.." class="input" name="text" type="text" [(ngModel)]="searchResult" (keyup.enter)="HeaderComponent_FilterData()">
    </div>
   </li>
    <li *ngIf="bDisplayDotsDropdown" class="nav-item dropdown" ngbDropdown placement="bottom-right">
        <a ngbDropdownToggle class="nav-link dropdown-toggle" href="javascript:void(0)" id="navbarDropdown"
            role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class=" fas fa-ellipsis-v text-white"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown" ngbDropdownMenu>
            <button routerLink="apps/user-management" class="dropdown-item text-67757c"><i class="mdi mdi-account"></i> Users</button>
            <button routerLink="apps/groups" class="dropdown-item text-67757c"><i class="mdi mdi-account-multiple"></i>  Groups</button>
            <button routerLink="apps/roles" class="dropdown-item text-67757c"><i class="mdi mdi-gavel"></i> Roles</button>
            <!-- <button routerLink="apps/tenants" class="dropdown-item text-67757c" ><i class="mdi mdi-domain"></i> Tenants</button> -->
            <button routerLink="apps/logs" class="dropdown-item border-top text-67757c"><i class="mdi mdi-cube"></i> Logs</button>
        </div>
    </li>
    <li class="nav-item" ngbDropdown placement="bottom-right">
        <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark pro-pic d-flex align-items-center" href="javascript:void(0)"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <!-- <img src="assets/images/users/user.png" alt="user" class="rounded-circle" width="50"> -->
            <i class="fa fa-user-circle text-white" style="font-size: 35px;"></i>
        </a>
        <div class="dropdown-menu-right user-dd" ngbDropdownMenu>
            <div class="d-flex no-block align-items-center p-15  m-b-10">
                <div class="">
                   
                </div>
                <div class="m-l-10">
                    <h4 class="m-b-0 font-15">{{sUsername}}</h4>                    
                </div>
            </div>
            <a class="dropdown-item" (click)="VerticalNavigationComponent_Logout()">
                <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
        </div>
    </li>
    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
</ul>

