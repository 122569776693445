import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from "@angular/common/http";
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  httpOptions: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-app-name': 'auth',
      'x-org-name': 'cloud',
    })
  }
  constructor(private http: HttpClient) { }
  AuthService_Login(credentials : any): Observable<any> {
    return this.http.post(environment.BaseURL+'/ajaxLogin',
        `${credentials}`
    , this.httpOptions);
  }
  AuthService_SocialLogin(body) : Observable<any>
  {
    return this.http.post<any>(environment.BaseURL +  '/_oauth2/url' , body , this.httpOptions );
  }

  AuthService_GetATNonce(body) : Observable<any>
  {
    return this.http.get<any>(environment.BaseURL +  '/_oauth2/nonceLogin?a_t_nonce=' + body , this.httpOptions );
  }
  
   // access token login
  //  AuthService_AccessTokenLogin(body): Observable<any> {
  //   return this.http.post(environment.BaseURL+'/authorize', body , this.httpOptions);
  // }
  public getToken() {
    return localStorage.getItem(environment.AccessToken);
  }
  //Get Congifurations
  AuthService_GetConfigurations(): Observable<any> 
  {
    const sHOST : string = window.location.host;
    console.log("URL is ===>>>>",sHOST)
    if(sHOST?.includes('staging'))
    {
      return this.http.post(environment.BaseURL+'/ds/function/shared/ProjectConfigStaging', {}, this.httpOptions);
    }
    else
    {
      return this.http.post(environment.BaseURL+'/ds/function/shared/ProjectConfig', {}, this.httpOptions);
    }
    // commented API to get confgs from bucket
    // return this.http.get<any>('https://storage.googleapis.com/'+environment.BucketName+'/groups/configs/configs.json');
  }
}
