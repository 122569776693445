import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;
import { version } from 'package.json';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { cSessionService } from 'src/app/services/session.service';
import { environment } from 'src/environments/environment';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {
  public config: PerfectScrollbarConfigInterface = {};
  sBuildVersion : string;
  lMenuConfiguration : any;
  lFooterConfiguration : boolean;
  oDisplayConfigurations : any = {logoCollapse : "", logoExpand : ""};
  oProjectConfigurations : any = {title : ''};
  singlePageMargins: string;
  bLogoSize : boolean = false;
  addMatchedDocClass: string;
  constructor(public router: Router,private oSessionService : cSessionService,private oDataService: DataService) 
  {
    this.oProjectConfigurations.title = environment.Title;
    this.oDisplayConfigurations.logoCollapse = environment.ImagesURL+'logo-icon.png';
    this.oDisplayConfigurations.logoExpand = environment.ImagesURL+'logo-text.png';
    this.sBuildVersion = "";
    if(environment.BaseURL == 'https://app.talentnet.trilloapps.com')
    {
      this.bLogoSize = true;
    }
    else
    {
      this.bLogoSize = false;
    }
   }

  tabStatus = 'justified';

  public isCollapsed = false;

  public innerWidth: any;
  public defaultSidebar: any;
  public showSettings = false;
  public showMobileMenu = false;
  public expandLogo = false;
  public logoLogin = false;

  options = {
    theme: 'light', // two possible values: light, dark
    dir: 'ltr', // two possible values: ltr, rtl
    layout: 'vertical', // two possible values: vertical, horizontal
    sidebartype: 'full', // four possible values: full, iconbar, overlay, mini-sidebar
    sidebarpos: 'fixed', // two possible values: fixed, absolute
    headerpos: 'fixed', // two possible values: fixed, absolute
    boxed: 'full', // two possible values: full, boxed
    navbarbg: 'skin6', // six possible values: skin(1/2/3/4/5/6)
    sidebarbg: 'skin8', // six possible values: skin(1/2/3/4/5/6)
    logobg: 'skin6' // six possible values: skin(1/2/3/4/5/6)
  };

  Logo() {
    this.expandLogo = !this.expandLogo;
  }

  ngOnInit() {
    let lConfiguration = this.oSessionService.cSessionService_GetConfigurations();
    this.lMenuConfiguration = lConfiguration.menus;
    this.lFooterConfiguration = lConfiguration.footer;
    if (this.router.url === '/') {
      this.router.navigate(['/dashboard/classic']);
    }
    this.defaultSidebar = this.options.sidebartype;
    this.handleSidebar();
    this.singlePageMargins = lConfiguration.menus.length<=1 ?'master-page-margin': ''; 
    if(environment.Title === 'Talentnet')
    {
      this.addMatchedDocClass = 'match-doc'
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: string) {
    this.handleSidebar();
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    switch (this.defaultSidebar) {
      case 'full':
      case 'iconbar':
        if (this.innerWidth < 1170) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      case 'overlay':
        if (this.innerWidth < 767) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  toggleSidebarType() {
    switch (this.options.sidebartype) {
      case 'full':
        this.logoLogin = !this.logoLogin
      case 'iconbar':
        this.options.sidebartype = 'mini-sidebar';
        break;

      case 'overlay':
        this.showMobileMenu = !this.showMobileMenu;
        break;

      case 'mini-sidebar':
        if (this.defaultSidebar === 'mini-sidebar') {
          this.options.sidebartype = 'full';
        } else {
          this.logoLogin = !this.logoLogin
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  handleClick(event: boolean) {
    this.showMobileMenu = event;
  }
  ToggleView()
  {
    this.oDataService.bDisplayTableButtons.next(this.isCollapsed)
  }
}
